<template>
  <b-container>
    <b-row>
      <b-col
        lg="4"
        md="6"
        sm="12"
      >
        <p>
          <img
            :src="require('@/assets/images/logo/img.png')"
            class="p-1"
            height="80"
          >
          <img
            :src="require('@/assets/images/logo/symbol-tajikistan.png')"
            class="p-1"
            height="80"
          >
        </p>
        <p>
          Проект профинансирован Программой развития ООН <br>
          2022 Все права защищены | Разработано ООО "Ребус"
        </p>
      </b-col>

      <b-col
        lg="4"
        md="6"
        sm="12"
        v-for="(data, index) in menus"
        :key="index"
      >
        <h4>{{ data.title }}</h4>

        <ul>
          <li v-for="(childData, childIndex) in data.child" :key="childIndex"><b-link :to="childData.href">{{ childData.title }}</b-link></li>
        </ul>
      </b-col>

    </b-row>

  </b-container>
</template>

<script>

import { BContainer, BRow, BCol, BLink } from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BLink,
  },
  data() {
    return {
      menus: [],
    }
  },
  created() {
    this.$http.get('https://edu.startupchoikhona.tj/backend/api/website/footer-menu', {})
      .then(response => {
        this.menus = response.data.data
      })
  },
}
</script>

<style>

</style>
