<template>
  <b-link
      class="navbar-brand"
      to="/"
  >
          <span class="brand-logo-l">
            <b-img
                class="h-100"
                :src="appLogoImage"
                alt="logo"
            />
          </span>
  </b-link>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style>

</style>
