<template>
  <div class="navbar-container d-flex content align-items-center">
    <b-container>
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
            />
          </b-link>
        </li>
      </ul>

      <!-- Left Col -->
      <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
        <app-navbar-horizontal-layout-brand />

        <b-nav
          class="m-auto d-none d-md-none d-lg-flex l"
          align="center"
        >
          <b-nav-item
            v-for="(data, index) in menus"
            :key="index"
          >
            <router-link :to="data.path">
              {{ data.title }}
            </router-link>
          </b-nav-item>
        </b-nav>

      </div>

      <b-navbar-nav class="nav align-items-center ml-auto">

        <b-nav-item-dropdown
          v-if="userData"
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ userData.name || userData.email }}
              </p>
              <span class="user-status">{{ roleTranslate[userData.roles[0]] }}</span>
            </div>
            <b-avatar
              size="40"
              :src="userData.photo ? 'https://edu.startupchoikhona.tj/backend/'+userData.photo : ''"
              variant="light-primary"
              badge
              class="badge-minimal"
              badge-variant="success"
            >
              <feather-icon
                v-if="!userData.name"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
          </template>

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="redirectToPanel"
          >
            <div>
              <feather-icon
                  size="16"
                  icon="ServerIcon"
                  class="mr-50"
              />
              <span>Панель управления</span>
            </div>

          </b-dropdown-item>

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="redirectToProfile"
          >
            <feather-icon
              size="16"
              icon="UserIcon"
              class="mr-50"
            />
            <span>Профиль</span>
          </b-dropdown-item>

          <b-dropdown-divider v-show="false" />

          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
          >
            <feather-icon
              size="16"
              icon="LogOutIcon"
              class="mr-50"
            />
            <span>Выйти</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-link
          v-else
          class="btn btn-primary"
          to="/login"
        >
          <feather-icon
            size="16"
            icon="LogInIcon"
            class="mr-50"
          />
          Авторизация
        </b-link>

      </b-navbar-nav>

    </b-container>
  </div>
</template>

<script>
import {
  BContainer, BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BNav, BNavItem,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import AppNavbarHorizontalLayoutBrand
from '@core/layouts/components/app-navbar/landing/AppNavbarHorizontalLandingLayoutBrand.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BNav,
    BNavItem,
    BContainer,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    AppNavbarHorizontalLayoutBrand,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      statusAuth: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      menus: [],
      roleTranslate: {
        admin: 'Администратор',
        student: 'Студент',
        teacher: 'Ментор',
      },
    }
  },
  mounted() {
    this.$http.get('https://edu.startupchoikhona.tj/backend/api/website/navbar-menu', {})
      .then(response => {
        this.menus = response.data.data
      })
  },
  methods: {
    logout() {
      this.$http.post('https://edu.startupchoikhona.tj/backend/api/logout', {})
        .then(() => {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')

          // Reset ability
          this.$ability.update(initialAbility)

          // Redirect to login page
          this.$router.go()
          // this.$router.push({ name: 'login' })
        })
    },
    redirectToPanel() {
      this.$router.push(getHomeRouteForLoggedInUser(this.userData.roles[0]))
    },
    redirectToProfile() {
      this.$router.push({ name: 'profile-page-dashboard' })
    },
  },
}
</script>

<style>
.dropdown-menu.dropdown-menu-right.show {
  width: 100% !important;
}
</style>
